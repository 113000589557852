import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'

import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {
  render() {
    let page = this.props.data.wordpressPage
    let metaDescription = page.yoast_meta.find(x => x.name === 'description') ? page.yoast_meta.find(x => x.name === 'description').content : '';
    return (
      <>
        <SEO title={he.decode(page.yoast_title)} bodyClass={page.slug} description={metaDescription} />
        { page.slug !== 'home' &&
          <section className='breadcrumbs'>
            <div className='breadcrumbs__inner'>
              <ul>
                <li><Link to='/'>Home</Link></li>
                <li>{he.decode(page.title)}</li>
              </ul>
            </div>
          </section>
        }
        { page.acf.content_blocks_page && page.acf.content_blocks_page.map((el, i) => {
          return RenderBlock(el.__typename, el, i)
        }) }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wordpressPage(id: {eq: $id}) {
      title
      slug
      yoast_title
      yoast_meta {  
       name
       content
       property
      }
      acf {
        content_blocks_page {
          __typename
          ... on WordPressAcf_sectors {
            sectors {
              title: post_title
              slug: post_name
              acf {
                featured_image {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_content_video_image {
            title
            content
            background_image {
              localFile {
                childImageSharp {
                  fixed(width: 2600) {
                    src
                  }
                }
              }
            }
            video_content
            video
            video_image {
              localFile {
                childImageSharp {
                  fixed(width: 500) {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_video_image {
            background_image {
              localFile {
                childImageSharp {
                  fixed(width: 2600) {
                    src
                  }
                }
              }
            }
            video_content
            video
            video_image {
              localFile {
                childImageSharp {
                  fixed(width: 500) {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_our_services {
            title
            subtitle
            content
          }
          ... on WordPressAcf_basic_page {
            title
            content
          }
          ... on WordPressAcf_page_header {
            title
            content
          }
          ... on WordPressAcf_three_column_content {
            column {
              title
              content
            }
            text_color
            background_color
          }
          ... on WordPressAcf_letters {
            f_image {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
            s_image {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
            c_image {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_contact_page {
            title
            address
            phone
            google_map {
              lat
              lng
            }
            banner_image {
              localFile {
                childImageSharp {
                  fixed(width: 2600) {
                    src
                  }
                }
              }
            }
            image_caption
          }
          ... on WordPressAcf_image_content {
            rows {
              image {
                localFile {
                  childImageSharp {
                    fixed(width: 1400) {
                      src
                    }
                  }
                }
              }
              title
              content
            }
          }
          ... on WordPressAcf_slim_contact {
            button_text
            button_link
          }
          ... on WordPressAcf_team {
            team {
              title: post_title
              acf {
                profile_picture {
                  localFile {
                    childImageSharp {
                      fixed(width: 800) {
                        src
                      }
                    }
                  }
                }
                position
                linkedin
                short_bio
                full_bio
              }
            }
          }
          ... on WordPressAcf_cross_partner_block {
            title
            subtitle
            link
            button_text
            featured_image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            logo {
              localFile {
                childImageSharp {
                  fixed(height: 43) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate